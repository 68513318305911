import React from "react";
import { OneLessonADayBadge } from "@Ignite-Reading/ui-kit/icons";

import { Tooltip, TooltipContent, TooltipTrigger } from "../Tooltip";

export default function oneLessonADayBadge({ "data-testid": testId = "one-lesson-a-day-badge" }) {
  return (
    <Tooltip>
      <TooltipTrigger>
        <span data-testid={testId}>
          <OneLessonADayBadge className="text-brand-700" />
        </span>
      </TooltipTrigger>
      <TooltipContent>One Lesson a Day</TooltipContent>
    </Tooltip>
  );
}
